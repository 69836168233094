import React from 'react';

// Components
import RainbowLogo from '../../components/RainbowLogo/RainbowLogo';

// CSS
import './SplashScreen.css';

export default class SplashScreen extends React.Component {
  render() {
    return (
      <div className="SplashScreen">
        <RainbowLogo size="5" />
      </div>
    )
  }
}