import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';

// Containers Comp
import SplashScreen from '../SplashScreen/SplashScreen';

const App: React.FC = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/" component={SplashScreen} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
