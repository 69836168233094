import React from 'react';

// CSS
import './RainbowLogo.css';

type RLProps = {
  size?: string
}

export default class RainbowLogo extends React.Component<RLProps> {
  render() {
    return (
      <span className="RainbowLogo" style={{ 
        fontSize: this.props.size + "em",
        fontWeight: 800
      }}>
        kale.mba
      </span>
    )
  }
}